
import { Component, Vue } from "vue-property-decorator";

@Component({})
export default class AdminHome extends Vue {
  startTime: number = new Date().setHours(0, 0, 0, 0);
  endTime: number = Date.now() + 24 * 60 * 60 * 1000;

  get dateModel() {
    return [{ from: this.fromDate, to: this.toDate }];
  }

  set dateModel(value) {
    if (!value) {
      this.startTime = new Date().setHours(0, 0, 0, 0);
      this.endTime = Date.now() + 24 * 60 * 60 * 1000;
    } else {
      //@ts-ignore
      const { from, to } = value;
      if (from && to) {
        this.fromDate = from;
        this.toDate = to;
      }
    }
  }

  get fromDate() {
    //@ts-ignore
    return this.$options.filters.moment(this.startTime, "YYYY/MM/DD");
  }

  set fromDate(value) {
    this.startTime = new Date(value).getTime();
  }

  get toDate() {
    //@ts-ignore
    return this.$options.filters.moment(this.endTime, "YYYY/MM/DD");
  }
  set toDate(value) {
    this.endTime = new Date(value).getTime();
  }
}
