var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{attrs:{"id":"admin-home"}},[_c('div',{staticClass:"row"},[_c('q-input',{staticClass:"col",attrs:{"value":_vm.fromDate + ' - ' + _vm.toDate,"readonly":"","bg-color":"deposit-input","label-color":"grey","label":"Период","input-style":"color: white;","outlined":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('q-icon',{staticClass:"cursor-pointer",attrs:{"name":"event","color":"white"}},[_c('q-popup-proxy',{ref:"qDateProxy",attrs:{"transition-show":"scale","transition-hide":"scale"}},[_c('q-date',{attrs:{"dark":"","today-btn":true,"minimal":true,"range":true},model:{value:(_vm.dateModel),callback:function ($$v) {_vm.dateModel=$$v},expression:"dateModel"}},[_c('div',{staticClass:"row items-center justify-end"},[_c('q-btn',{directives:[{name:"close-popup",rawName:"v-close-popup"}],attrs:{"label":"Close","color":"primary","flat":""}})],1)])],1)],1)]},proxy:true}])})],1),_c('ApolloQuery',{attrs:{"query":gql => gql`
        query($startTime: Float!, $endTime: Float!) {
          getSteamBotsOffersStatsByPartners(
            startTime: $startTime
            endTime: $endTime
          )
          getSteamBotsOffersStats(startTime: $startTime, endTime: $endTime)
        }
      `,"variables":{ startTime: _vm.startTime, endTime: _vm.endTime }},scopedSlots:_vm._u([{key:"default",fn:function({ result: { data } }){return [_c('h2',[_vm._v(" Партнеры ")]),(
          data && Object.keys(data.getSteamBotsOffersStatsByPartners).length
        )?[_vm._l((data.getSteamBotsOffersStatsByPartners),function(partner){return [_c('q-chip',{attrs:{"square":""}},[_c('q-avatar',[_c('img',{attrs:{"src":partner.partnerUser.avatar}})]),_vm._v(" "+_vm._s(partner.partnerUser.name)+" "+_vm._s({ WITHDRAW: partner.WITHDRAW || 0, DEPOSIT: partner.DEPOSIT || 0 })+" ")],1)]})]:[_vm._v(" Нечего показать за этот период. ")],_c('h2',[_vm._v(" Боты ")]),(data && Object.keys(data.getSteamBotsOffersStats).length)?[_vm._l((data.getSteamBotsOffersStats),function(bot,_id){return [_c('q-chip',{attrs:{"square":""}},[_vm._v(" "+_vm._s(_id)+" "+_vm._s(bot)+" ")])]})]:[_vm._v(" Нечего показать за этот период. ")]]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }